@import '../../styles/helpers/index.scss';

.page-footer {
  padding: 90px 0 25px;
  background-color: #000;
  color: #fff;

  @media screen and (max-width: $tablet-md) {
    padding: 90px 0 80px;
  }

  &__inner {
    @include space-between;

    @media screen and (max-width: $tablet-md) {
      flex-direction: column;
    }
  }

  &__heading {
    img {
      object-fit: cover;
      user-select: none;
    }
  }

  &__grid {
    @include grid($columns: 3, $column-gap: 40px);

    @media screen and (max-width: $tablet-md) {
      margin: 30px 0 0;
    }

    & > div {
      span {
        display: block;
        margin: 0 0 15px;
        font-size: 12px;
        line-height: 180%;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        opacity: 0.4;
      }

      & > *:not(span) {
        font-size: 14px;
        line-height: 200%;
        letter-spacing: 0.28px;
      }

      a {
        color: #fff;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  &__underline {
    margin: 65px 0 0;
    padding: 20px 0 0;
    border-top: 1px solid rgba(#fff, 0.5);

    @include space-between;

    font-size: 12px;
    line-height: 150%;
    letter-spacing: 0.24px;
    opacity: 0.5;
  }
}

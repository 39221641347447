@use 'scss-reset/reset';

@import 'slick-carousel/slick/slick';
@import 'slick-carousel/slick/slick-theme';

@import './helpers/variables.scss';
@import './helpers/mixins.scss';
@import './typography.scss';

*,
*:before,
*:after {
  box-sizing: inherit;
}

// *::-webkit-scrollbar {
//   display: none;
// }

html {
  height: 100%;
  box-sizing: border-box;
}

body {
  height: inherit;
  background-color: map-get($colors, 'background');
  font-size: 18px;
  font-weight: 400;
  line-height: 1.24;
  font-family: 'Helvetica Neue', sans-serif;
  color: map-get($colors, 'text');

  overflow: auto;
  overflow-x: hidden;

  @media screen and (max-width: $tablet-md) {
    font-size: 16px;
  }

  #root {
    height: inherit;

    display: flex;
    flex-direction: column;

    main {
      flex: 1 0 auto;
      padding-top: 82px;

      transition: all 0.3s ease;
    }
  }
}

section {
  &:not(:first-of-type) {
    margin: 120px 0 0;

    @media screen and (max-width: $tablet-md) {
      margin: 60px 0 0;
    }
  }
}

textarea {
  resize: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: top;
}

.btn {
  cursor: pointer;
  padding: 10px 20px;
  border-width: 1px;
  border-style: solid;
  text-decoration: none;
  transition: all 0.3s ease;
  font-family: 'Helvetica 55 Roman', sans-serif;
  font-weight: 550;
  text-align: center;

  &:hover {
    background-color: #fff;
    color: map-get($colors, 'accent');
    border-color: map-get($colors, 'accent');
  }

  &.transparent {
    color: map-get($colors, 'text');
    border-color: map-get($colors, 'text');
  }

  &.accent {
    color: #fff;
    border-color: map-get($colors, 'accent');
    background-color: map-get($colors, 'accent');
    text-transform: uppercase;
  }

  &.black {
    color: #fff;
    border-color: #000;
    background-color: #000;
    text-transform: uppercase;
  }
}

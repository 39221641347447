@import '../../styles/helpers/index.scss';

.reviews {
  margin: 0 !important;
  padding: 100px 0 60px;

  &__list {
    margin: 30px auto 0;

    &--slide {
      background-color: #fff;
      padding: 15px 30px 30px;
      border-radius: 20px;
      box-shadow: 0 3px 5px 0 rgba(#000, 0.3);
      margin: 0 0 15px;

      @media screen and (max-width: $tablet-md) {
        padding: 15px 15px;
      }

      .photo {
        width: 150px;
        height: 150px;
        border-radius: 50%;

        margin: 0 auto;
        overflow: hidden;
        box-shadow: 0 5px 5px 0 rgba(#000, 0.75);

        img {
          width: 100%;
          height: auto;
          object-fit: contain;
        }
      }

      .text {
        margin: 15px 0 0;
        text-align: center;

        .name {
          font-family: 'Helvetica 65 Medium', sans-serif;
          font-weight: 700;
          font-size: 28px;
          color: map-get($colors, 'accent');

          @media screen and (max-width: $tablet-md) {
            font-size: 20px;
          }
        }

        .company {
          font-family: 'Helvetica 65 Medium', sans-serif;
          font-size: 12px;
          font-weight: 700;
          opacity: 0.6;
        }

        p {
          font-family: 'Helvetica 65 Medium', sans-serif;
          border-top: 1px solid rgba(#000, 0.2);
          padding: 15px 0 0;
          margin: 15px 0 0;
          font-size: 18px;
          min-height: 70px;
          height: 150px;
          overflow: scroll;

          @media screen and (max-width: $tablet-md) {
            font-size: 14px;
            margin: 10px 0 0;
            padding: 10px 0 0;
          }
        }
      }
    }
  }
}

.slick-slide {
  opacity: 0.5;
  transform: scale(0.75);
  transition: all 0.3s ease;

  &.slick-current {
    opacity: 1 !important;
    transform: scale(1);
  }
}

.slick-dots {
  bottom: -50px !important;

  li {
    button::before {
      font-size: 18px !important;
    }

    &.slick-active {
      transform: scale(1.5);

      button::before {
        color: map-get($colors, 'accent') !important;
      }
    }
  }
}

@import '../../styles/helpers/index.scss';

.hero {
  &__inner {
    padding: 100px 0 130px;

    @include space-between;
    gap: 60px;

    @media screen and (max-width: $tablet-sm) {
      padding: 30px 0;
    }

    @media screen and (min-width: $tablet-sm) and (max-width: $desktop-md) {
      padding: 100px 0 90px;
    }

    & > div {
      width: 100%;
    }
  }

  &__content {
    position: relative;

    &::before {
      @include pseudo-size(270px, 160px);
      @include background-image('../../static/pink_arrow.svg', cover);
      @include absolute(50%, auto, -150px);

      transform: translateX(-50%);

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }
    }

    h1 {
      margin: 0 0 30px;
      position: relative;

      &::before {
        @include pseudo-size(315px, 10px);
        @include background-image('../../static/pink_line.svg', cover);
        @include absolute(80px, 0);

        @media screen and (max-width: $tablet-sm) {
          background-image: url('../../static/pink_arrow_sm.svg');

          height: 20px;
          width: 60px;
          left: auto;
          right: 0;
          top: 15px;
        }
      }
    }

    .mobile-image {
      margin: 40px 0 50px;
      @include background-image('../../static/hero.svg', contain);

      min-height: 200px;

      display: none;

      @media screen and (max-width: $tablet-sm) {
        display: block;
      }
    }

    .links {
      margin: 30px 0 0;
      @include align-center;
      gap: 35px;

      @media screen and (max-width: $tablet-sm) {
        flex-direction: column;
        gap: 15px;
      }

      .btn {
        @media screen and (max-width: $tablet-sm) {
          width: 100%;
          font-weight: bold;
        }
      }

      span {
        font-family: 'Helvetica 65 Medium', sans-serif;
        font-size: 24px;
        font-weight: 700;
        line-height: 200%;

        a {
          color: #000;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  &__image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    @media screen and (max-width: $tablet-sm) {
      display: none;
    }
  }

  &__ticker {
    @include align-center;
    background-color: map-get($colors, 'yellow');
    border: 2px solid #000;
    height: 54px;
    max-width: 100%;

    & > .container {
      height: auto;

      @media screen and (max-width: $tablet-sm) {
        padding: 0;
      }
    }

    .items-wrap {
      position: relative;
      display: flex;
      overflow: hidden;
      user-select: none;
      gap: 20px;
    }

    .items {
      @include space-around;
      flex-shrink: 0;
      gap: 10px;
      min-width: 100%;
    }

    .item {
      cursor: pointer;
      font-family: 'Helvetica 55 Roman', sans-serif;
      font-weight: 550;
      font-size: 24px;
      line-height: 100%;
      color: map-get($colors, 'text');
      margin: 10px 0;
      transition: all 0.1s ease-in-out;
      @include scale;
      text-transform: lowercase;
    }

    .marquee {
      animation: scroll 20s linear infinite;
    }

    .items-wrap:hover .marquee {
      animation-play-state: paused;
    }
  }
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 20px));
  }
}

@import '../../styles/helpers/index.scss';

.cta {
  margin: 0 !important;
  position: relative;
  padding: 115px 0 170px;
  background-color: map-get($colors, 'accent');
  overflow: hidden;

  @media screen and (max-width: $tablet-md) {
    padding: 60px 0 100px;
  }

  &::before {
    @include pseudo-size(355px, 250px);
    @include background-image('../../static/cta.svg');
    @include absolute(auto, auto, 5%);
    bottom: 0;

    @media screen and (max-width: $tablet-md) {
      width: 200px;
      height: 90px;
      background-size: contain;
      right: -5%;
    }
  }

  &__inner {
    position: relative;
    text-align: center;
    color: #fff;

    .btn {
      margin: 30px 0 0;
      display: inline-block;
    }
  }
}

@import '../../styles/helpers/index.scss';

.case-studies {
  background-color: #fff;
  margin: 0 !important;
  padding: 240px 0 120px;

  @media screen and (max-width: $tablet-md) {
    padding: 200px 0 60px;
  }

  &__list {
    margin: 30px 0 0;
    @include grid;

    & > div:last-of-type {
      @include grid($row-gap: 60px);
    }

    a {
      text-decoration: none;
      @include scale;

      &:hover {
        .title {
          color: map-get($colors, 'accent');
        }

        @media screen and (max-width: $tablet-md) {
          transform: none;
        }
      }

      img {
        height: 100%;
        width: auto;
        object-fit: cover;

        @media screen and (max-width: $tablet-md) {
          max-height: 250px;
          width: 100%;
        }
      }

      .title {
        margin: 10px 0 0;
        font-family: 'Helvetica 65 Medium', sans-serif;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.36px;
        color: map-get($colors, 'text');
      }
    }
  }

  .btn {
    display: block;
    max-width: 220px;

    margin: 80px auto 0;
  }
}

@import '../../styles/helpers/index.scss';

.video-block {
  margin: 0 !important;
  padding: 130px 0 550px;
  background-color: map-get($colors, 'accent');
  color: #fff;
  text-align: center;

  @media screen and (max-width: $tablet-sm) {
    padding: 80px 0 175px;
  }

  h2 {
    position: relative;
    margin: 0 auto;
    max-width: 750px;

    @media screen and (max-width: $tablet-md) {
      max-width: unset;
    }

    &::before {
      @include pseudo-size(375px, 10px);
      @include background-image('../../static/yellow_line.svg');
      @include absolute(65px);

      @media screen and (max-width: $tablet-sm) {
        width: 260px;
        top: 75px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  p {
    max-width: 650px;
    margin: 30px auto 0;
    font-size: 18px;
    line-height: 143%;
    font-weight: 400;

    @media screen and (max-width: $tablet-sm) {
      max-width: unset;
      margin: 25px auto 0;
    }
  }

  .video-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;

    margin: 60px 0 0;

    img {
      margin: 0 auto;
    }

    & > div {
      width: 100% !important;
      height: 600px !important;
      border-radius: 30px;
      overflow: hidden;

      @media screen and (max-width: $tablet-sm) {
        height: 200px !important;
      }
    }

    @media screen and (max-width: $tablet-sm) {
      padding: 0 20px;
    }
  }
}

.react-player__preview {
  position: relative;
}

.play-icon {
  @include background-image('../../static/play-icon.svg');
  @include square(120px);
  @include absolute-centering(50%);

  @media screen and (max-width: $tablet-sm) {
    @include square(60px);
  }
}

@import '../../styles/helpers/index.scss';

.page-header {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 0.3s ease;

  @media screen and (max-width: $tablet-md) {
    top: 0;
    background-color: map-get($colors, 'background');
  }

  &.shadow {
    box-shadow: 0 5px 15px 0 rgba(#000, 0.5);
    top: 0;
    background-color: map-get($colors, 'background');
  }

  &__inner {
    padding: 20px 0;

    @include space-between;
    align-items: center;

    nav {
      position: relative;
      @include align-center;
      gap: 30px;
      transition: left 0.3s ease;

      &.open {
        left: 0;
      }

      .close {
        position: absolute;
        top: 10px;
        right: 15px;

        @media screen and (min-width: $tablet-md) {
          display: none;
        }
      }

      .btn {
        display: none;

        @media screen and (max-width: $tablet-md) {
          display: inline-flex;
        }
      }

      @media screen and (max-width: $tablet-md) {
        position: absolute;
        top: 0;
        left: -150%;

        width: 100vw;
        height: 100vh;

        flex-direction: column;
        background-color: #fff;

        justify-content: center;
      }
    }

    .nav-menu {
      @include align-center;
      gap: 25px;

      @media screen and (max-width: $tablet-md) {
        flex-direction: column;
      }

      a {
        cursor: pointer;
        color: map-get($colors, 'text');
        text-transform: uppercase;
        font-family: 'Helvetica 55 Roman', sans-serif;
        font-size: 16px;
        font-weight: 550;
        text-decoration: none;

        &:hover,
        &.active {
          color: map-get($colors, 'accent');
        }
      }
    }

    .buttons {
      @include align-center;
      gap: 15px;

      @media screen and (max-width: $tablet-md) {
        display: none;
      }
    }
  }
}

.burger {
  display: none;
  width: 30px;

  span {
    display: block;
    border-bottom: 2px solid #000;
    &:nth-of-type(2) {
      margin: 8px 0;
    }
  }

  @media screen and (max-width: $tablet-md) {
    display: block;
  }
}

.close {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: 40px;
  height: 40px;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: calc((40px - 1px) / 2);
    left: 0;
    right: 0;
    height: 1px;
    // background: map-get($colors, 'darkblue');
    background-color: #000;
    border-radius: 1px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

@import '../../styles/helpers/index.scss';

.feedback-form {
  @include align-center;
  flex-direction: column;

  & > * {
    width: 100%;
  }

  input:not([type='submit']) {
    margin: 0 0 15px;
  }

  input,
  select {
    height: 56px;
    line-height: 56px;
    padding: 0 20px;
    color: #2e2e2e;
    font-family: 'Helvetica Neue', sans-serif;

    &::placeholder {
      font-family: inherit;
      font-weight: inherit;
      font-size: inherit;
      line-height: inherit;
    }
  }

  .btn {
    margin: 35px 0 0;
    max-width: 220px;
    line-height: 200%;
  }

  .data-protection,
  .notice {
    margin: 15px 0 0;
    @include align-center;
    justify-content: center;
    font-size: 14px;
    line-height: 100%;
    color: map-get($colors, 'text');
    text-align: center;
  }

  .notice {
    max-width: 80%;
    margin: 15px auto 0;
    opacity: 0.5;
  }
}

@import '../../styles/helpers/index.scss';

.partners {
  padding: 115px 0 90px;
  background-color: #fff;

  @media screen and (max-width: $tablet-md) {
    padding: 60px 0 40px;
  }

  &__inner {
    h2 {
      text-align: center;
    }
  }

  &__list {
    margin: 25px 0 0;
    @include align-center;
    justify-content: center;
    gap: 60px;

    @media screen and (max-width: $tablet-md) {
      margin: 35px 0 0;
      flex-wrap: wrap;
    }

    & > * {
      @include scale;
    }
  }
}

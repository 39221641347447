@import '../../styles/helpers/index.scss';

.container {
  position: relative;
  max-width: 1240px;
  width: 100%;
  height: inherit;
  margin: 0 auto;
  padding: 0;
  @media screen and (max-width: $desktop-sm) {
    max-width: 100%;
    padding: 0 15px;
  }
}

@import '../../styles/helpers/index.scss';

.about {
  margin: 0 !important;
  background-color: #000;
  color: #fff;

  &__inner {
    padding: 120px 0;
    @include grid($columns: 2, $column-gap: 50px);
  }

  &__content {
    &--card {
      &:not(:last-child) {
        margin: 0 0 45px;
      }

      header {
        @include align-center;
        gap: 25px;

        .title {
          font-family: 'Helvetica 65 Medium', sans-serif;
          font-size: 40px;
          font-weight: 700;
          line-height: 120%;

          @media screen and (max-width: $tablet-md) {
            font-size: 30px;
          }
        }
      }

      p {
        margin: 40px 0 0;
        font-family: 'Helvetica Neue', sans-serif;
        font-size: 18px;
        line-height: 150%;
      }
    }
  }

  &__form {
    .form-wrapper {
      padding: 40px 70px;
      background-color: #fff;
      box-shadow: 10px 10px 0 0 rgba(#eff4f5, 1);

      @media screen and (max-width: $tablet-sm) {
        order: 1;
        box-shadow: none;

        padding: 30px 15px;
      }

      .title {
        margin: 0 0 15px;
        color: map-get($colors, 'text');
        font-family: 'Helvetica 65 Medium', sans-serif;
        font-size: 28px;
        font-weight: 700;
        line-height: 50px;
        text-align: center;

        @media screen and (max-width: $tablet-sm) {
          line-height: 100%;
          font-size: 24px;
          margin: 0 0 30px;
        }
      }
    }

    .awards {
      position: relative;
      margin: 100px 0 0;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: $tablet-sm) {
        display: none;
      }

      &::before {
        @include pseudo-size(210px, 8px);
        @include background-image('../../static/yellow_line.svg', contain);
        @include absolute(0, 50%);

        transform: translateX(-50%);

        @media screen and (max-width: $tablet-sm) {
          top: -20px;
        }
      }

      & > div {
        @include scale;
        cursor: none;

        &:nth-of-type(2) {
          margin: 85px 0 0;
        }

        img {
          object-fit: cover;
          user-select: none;
        }
      }
    }
  }
}

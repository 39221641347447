@use 'sass:selector';
@import './variables.scss';

// GRID
@mixin grid($row-gap: 40px, $column-gap: 40px, $rows: 1fr, $columns: 2) {
  display: grid;
  grid-template-rows: $rows;
  grid-template-columns: repeat($columns, 1fr);
  grid-row-gap: $row-gap;
  grid-column-gap: $column-gap;
  @media screen and (max-width: $tablet-md) {
    grid-template-columns: repeat(1, 1fr);
  }
}

// FLEX
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin space-between {
  display: flex;
  justify-content: space-between;
}

@mixin space-around {
  display: flex;
  justify-content: space-around;
}

@mixin align-center {
  display: flex;
  align-items: center;
}

// ABSOLUTE POSITION
@mixin absolute-centering($left: 0) {
  position: absolute;
  top: 50%;
  left: $left;

  @if $left == 50% {
    transform: translate(-50%, -50%);
  } @else {
    transform: translateY(-50%);
  }
}

@mixin absolute($top: 0, $left: auto, $right: auto) {
  position: absolute;

  top: $top;
  left: $left;
  right: $right;
}

// HOVER
@mixin scale {
  transition: transform 0.3s ease;
  &:hover {
    transform: scale(1.1);
  }
}

@mixin hover-invert($bg, $color) {
  background-color: $bg;
  border: 1px solid $bg;
  color: $color;

  transition: all 0.3s ease;

  &:hover {
    background-color: $color;
    color: $bg;
  }
}

// SIZES
@mixin pseudo-size($width: 20px, $height: 20px) {
  content: '';

  width: $width;
  height: $height;
}

@mixin max-width($width: 0) {
  max-width: $width !important;

  @media screen and (max-width: $tablet-md) {
    max-width: unset !important;
  }
}

@mixin square($width) {
  width: $width;
  height: $width;
}

@mixin circle($width) {
  width: $width;
  height: $width;

  border-radius: 50%;
}

@mixin columns($desktop: 3, $mobile: 2) {
  columns: $desktop;

  @media screen and (max-width: $tablet-md) {
    columns: $mobile;
  }
}

// OTHER
@mixin background-image($url: '', $size: cover) {
  background-image: url($url);
  background-repeat: no-repeat;
  background-position: center;
  background-size: $size;
}
